import {
  Typography,
  TablePagination,
  CircularProgress,
} from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'
import {
  QueryBuilder as QueryBuilderIcon,
  Warning as WarningIcon,
} from '@material-ui/icons'
import type { Contract, FullAddress, Pro } from '@willig/types/api'
import { addDays, addMonths } from 'date-fns'
import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { CustomTooltip } from 'src/components/CustomtoolTip'
import { useGetList, useGetOne } from 'src/hooks/useQueries'
import { SafeHtml } from 'src/libs/SafeHtml'

const useStyles = makeStyles({
  table: {
    width: '90%',
    margin: '48px auto',
  },
  rowStyle: {
    backgroundColor: 'white',
  },
})

// eslint-disable-next-line @getify/proper-arrows/where
const StyledTableCell = withStyles((theme) => {
  return {
    head: {
      backgroundColor: theme.palette.background.paper,
      fontSize: 16,
      fontWeight: 'bold',
    },
  }
})(TableCell)

type AddressFilter = {
  end?: Date | any
  anomalies?: boolean
}

type AddressListProps = {
  proId: string
  filters: AddressFilter
}

export function AddressList(props: AddressListProps) {
  const { proId, filters } = props
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  const { data } = useGetList<FullAddress>('FullAddress', {
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'next_intervention_date', order: 'DESC' },
    filter: { pro_id: proId },
  })
  const { data: pro } = useGetOne<Pro>('Pro', { id: proId })

  const address = useMemo(() => {
    if (data) {
      setPage(0) // Reset actual page with data
      return filterAddress(data.data, filters)
    }
  }, [data, filters])

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const displayedData = useMemo(() => {
    const firstIndex = page * rowsPerPage
    if (address) {
      return address.slice(firstIndex, firstIndex + rowsPerPage)
    }
    return []
  }, [address, rowsPerPage, page])

  const classes = useStyles()

  if (displayedData.length === 0) {
    return <div></div>
  }

  return (
    <div className={classes.table}>
      {pro && data && (
        <Typography
          component="h3"
          variant="h4"
          color="textPrimary"
          align="left"
        >
          {pro.data.name}
        </Typography>
      )}
      {displayedData.length >= 1 && address && (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Adresse</StyledTableCell>
                  <StyledTableCell align="left">Code postal</StyledTableCell>
                  <StyledTableCell align="left">Ville</StyledTableCell>
                  <StyledTableCell align="left">Ref.Client</StyledTableCell>
                  <StyledTableCell align="left">
                    Dernière intervention
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    Date d'échéance
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    Problème détecté
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {displayedData.map((singleAddress: FullAddress) => {
                  return (
                    <TableRow
                      key={singleAddress.id}
                      className={classes.rowStyle}
                    >
                      <TableCell component="th" scope="row">
                        <Link to={`/Adresses/${singleAddress.id}`}>
                          {singleAddress.rue}
                        </Link>
                      </TableCell>
                      <TableCell align="left">
                        {singleAddress.code_postal}
                      </TableCell>
                      <TableCell align="left">{singleAddress.ville}</TableCell>
                      <TableCell align="left">
                        <GetOrderNumber
                          adressId={singleAddress.id}
                          proId={singleAddress.pro_id}
                        />
                      </TableCell>

                      <StyledDate
                        date={singleAddress.last_intervention_date}
                        target="last"
                        reported={singleAddress.last_intervention_status === 3}
                      />
                      <StyledDate
                        date={singleAddress.next_intervention_date}
                        target="next"
                        reported={false}
                      />
                      <AnomaliesCol
                        anomalies={singleAddress.anomalies}
                        reported={singleAddress.last_intervention_status === 3}
                      />
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={address.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Éléments par page :"
          />
        </>
      )}
    </div>
  )
}

type AnomaliesColProps = {
  anomalies?: any
  reported: boolean
}

function AnomaliesCol(props: AnomaliesColProps) {
  const { anomalies, reported } = props
  return (
    <TableCell align="left">
      <Tooltip arrow title="test">
        <>
          {anomalies &&
            anomalies.map((anomalie: any) => {
              const arrayAnomalie = Object.values(anomalie) as any
              return (
                <IconTooltip
                  key={Object.keys(anomalie)[0]}
                  title={arrayAnomalie[0].libelle_anomalies}
                  icon="warning"
                />
              )
            })}

          {reported && (
            <IconTooltip
              title="intervention annulée, à reprogrammer"
              icon="annulation"
            />
          )}
        </>
      </Tooltip>
    </TableCell>
  )
}

type StyledDateProps = {
  date?: string
  target: string
  reported: boolean | undefined
}

function StyledDate(props: StyledDateProps) {
  const { date, target, reported } = props
  const actualDate = new Date()
  const nextDate = date ? new Date(date) : ''
  let style = 'black'
  if (target === 'next') {
    if (nextDate > actualDate && nextDate >= addDays(actualDate, 30)) {
      style = 'green'
    } else if (nextDate > actualDate && nextDate <= addDays(actualDate, 30)) {
      style = 'orange'
    } else {
      style = 'red'
    }
  } else {
    style = 'black'
  }
  if (reported) {
    style = 'blue'
  }

  return (
    <TableCell align="left" style={{ color: `${style}`, fontWeight: 'bold' }}>
      {nextDate && nextDate.toLocaleDateString('fr')}
    </TableCell>
  )
}

function IconTooltip(props: any) {
  const { title, icon } = props
  return (
    <CustomTooltip
      title={
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '4px',
            gap: '1px',
          }}
        >
          <SafeHtml>{title}</SafeHtml>
        </div>
      }
      placement="right-end"
    >
      {icon === 'warning' ? (
        <WarningIcon />
      ) : (
        <QueryBuilderIcon style={{ color: 'orange' }} />
      )}
    </CustomTooltip>
  )
}

function filterAddress(addresses: FullAddress[], filter: AddressFilter) {
  let filterDate = addresses
  if (filter.end === 'nextMonth') {
    filterDate = addresses.filter(
      (address: any) =>
        new Date(address.next_intervention_date) <= addMonths(new Date(), 1) &&
        new Date(address.next_intervention_date) > new Date(),
    )
  }
  if (filter.end === 'past') {
    filterDate = addresses.filter(
      (address: any) =>
        new Date(address.next_intervention_date) < new Date() ||
        address.next_intervention_date === null,
    )
  }
  if (filter.anomalies) {
    return filterDate.filter((address: any) => address.has_anomalies === true)
  }
  return filterDate
}

type GetOrderNumberProps = {
  adressId: string
  proId: string | undefined
}

function GetOrderNumber(props: GetOrderNumberProps) {
  const { adressId, proId } = props
  const { data, isLoading } = useGetList<Contract>('Contract', {
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'pro_id', order: 'DESC' },
    filter: { adresse_id: adressId, pro_id: proId },
  })
  if (isLoading) return <CircularProgress />
  if (!data) return <p>Non Renseigné</p>

  const numOrders = data.data.map(
    (contract) => contract?.num_order ?? 'non renseigné',
  )
  return (
    <>
      {numOrders.map((numOrder) => (
        <p>{numOrder}</p>
      ))}
    </>
  )
}
